// REF: https://github.com/philipwalton/flexbugs#flexbug-4
.col {
  flex: 1 0 0%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
}
