.card {
  // Workaround for the image size bug
  // See: https://github.com/twbs/bootstrap/pull/28855
  min-height: 1px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
}

// REF: https://github.com/philipwalton/flexbugs#flexbug-4
@media (min-width: 576px) {
  .card-group > .card {
    flex: 1 0 0%;
  }
}
