.list-group-item-primary {
  color: #084298 !important;
  background-color: #cfe2ff !important;
}

.list-group-item-secondary {
  color: #41464b !important;
  background-color: #e2e3e5 !important;
}

.list-group-item-success {
  color: #0f5132 !important;
  background-color: #d1e7dd !important;
}

.list-group-item-danger {
  color: #842029 !important;
  background-color: #f8d7da !important;
}

.list-group-item-warning {
  color: #664d03 !important;
  background-color: #fff3cd !important;
}

.list-group-item-info {
  color: #055160 !important;
  background-color: #cff4fc !important;
}

.list-group-item-light {
  color: #636464 !important;
  background-color: #fefefe !important;
}

.list-group-item-dark {
  color: #141619 !important;
  background-color: #d3d3d4 !important;
}
