.table > :not(caption) > * > * {
  box-shadow: none !important;
}

.table.table-striped:not(.table-dark) > tbody > tr:nth-child(odd) > * {
  background-color: rgba(0 0 0 / 5%) !important;
}

.table-dark.table-striped > :not(caption) > *:nth-child(odd) > * {
  opacity: 0.9;
}

.table-dark.table-striped > thead > tr > th {
  opacity: 1 !important;
}

.table.table-hover:not(.table-dark) > tbody > tr:hover > * {
  background-color: rgba(0 0 0 / 5%) !important;
}

.table.table-hover.table-dark > tbody > tr:hover > * {
  opacity: 0.9;
}

.table.table-hover.table-striped > tbody > tr:hover > * {
  background-color: rgba(0 0 0 / 5%) !important;
}

.table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  background-color: rgba(0 0 0 / 5%) !important;
}

.table-dark.table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  background-color: rgba(0, 0, 0, 5) !important;
}
